import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import TitleUnderline from "./titleUnderline"

//logos de medios
import medio1 from "./images/infobae.svg"
import medio2 from "./images/elcomercio.svg"
import medio3 from "./images/gestion.svg"
import medio4 from "./images/rpp.svg"
import medio5 from "./images/peru21.svg"
import medio6 from "./images/andina.svg"

import useNearScreen from "@hooks/useNearScreen"

//Mostrar card de medios
const useStyles = makeStyles(theme => ({
  cardHide: {
    display: props => (props ? "block" : "none"),
  },
}))

//movimiento de flecha de boton
const IconButtonWrapper = styled.div`
  float: right;
  transform: rotate(0deg);
  overflow: hidden;
  transition: all 0.3s ease-out;
  -webkit-transform: ${props =>
    props.rotate === "true" ? `rotate(180deg)` : ""};
`

//data de medios
const data = [
  {
    image: medio1,
    title: "Los beneficios de implementar WhatsApp API en tu empresa",
    textbody:
      "Un 81% de compradores seguirán usando WhatsApp para realizar sus pedidos e, incluso, se podrían sumar más usuarios. Siendo la API una solución necesaria para las empresas.",
    link:
      "https://www.infobae.com/america/peru/2022/01/25/whatsapp-business-api-cuales-son-los-beneficios-de-implementar-esta-solucion-en-tu-empresa/  ",
  },
  {
    image: medio2,
    title:
      "¿Qué beneficios tiene implementar WhatsApp Business API en su empresa?",
    textbody:
      "En América Latina, las personas usan WhatsApp a niveles más altos que el promedio global, convirtiéndola en una app necesaria para atender clientes y gestionar los negocios online.",
    link:
      "https://elcomercio.pe/economia/whatsapp-business-api-que-beneficios-brinda-implementar-esta-solucion-en-su-empresa-nndc-noticia/ ",
    cardDesktop: false,
  },
  {
    image: medio3,
    title:
      "Apps de mensajería para mi negocio, ¿mejoran las ventas y atraen clientes?",
    textbody:
      "La mensajería instantánea en redes sociales ha transformado la manera en que las personas interactúan con las marcas. Conoce cómo mejoran las ventas y atraen más clientes.",
    link:
      "https://gestion.pe/tu-dinero/apps-de-mensajeria-gestion-en-vivo-apps-de-mensajeria-para-mi-negocio-mejoran-las-ventas-y-atraen-clientes-ventas-noticia/  ",
    cardDesktop: false,
  },
  {
    image: medio4,
    title:
      "¿Cómo obtener una compra satisfactoria a través de apps de mensajería?",
    textbody:
      "Hoy en día los usuarios también tienen la posibilidad de cerrar una compra por medios de apps de mensajería como WhatsApp. Conoce cómo un negocio puede mejorar esta experiencia.",
    link: "https://youtu.be/acmWN7xkKEc",
    cardDesktop: true,
  },
  {
    image: medio5,
    title: "¿Por qué implementar WhatsApp Business API a tu empresa?",
    textbody:
      "La API de WhatsApp tiene muchos beneficios para los negocios como la implementación de chatbots, la opción multiagente, el envío de notificaciones personalizadas y más.",
    link:
      "https://peru21.pe/cheka/tecnologia/que-beneficios-ofrece-la-implementacion-de-whatsapp-business-api-a-tu-empresa-tecnologia-whatsapp-empresas-noticia/  ",
    cardDesktop: true,
  },
  {
    image: medio6,
    title: "Cuatro ventajas de contar con una plataforma omnicanal",
    textbody:
      "El comercio electrónico en nuestro país continúa consolidándose. Por eso, es necesario implementar una experiencia de compra más ágil con una plataforma omnicanal.",
    link:
      "https://andina.pe/agencia/noticia-cuatro-ventajas-contar-una-plataforma-omnicanal-877734.aspx",
    cardDesktop: true,
  },
]

const Medios = ({ pageAgradecimiento }) => {
  const [windowCard, setWindowCard] = useState(true)
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  useEffect(() => {
    let windowWidth = window.screen.width
    if (windowWidth < 600) {
      setWindowCard(false)
    } else {
      setWindowCard(true)
    }
  }, [windowCard])

  const [mostrar, setMostrar] = useState(false)

  const classes = useStyles(mostrar)

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  return (
    <section className="container-medios">
      <p className="container-medios-title">
        Nuestra tecnología omnicanal da que hablar en los{" "}
        <TitleUnderline underline="medios" />
      </p>

      <div className="container-medios-cards-desktop">
        {data.map((elemento, i) => {
          return (
            <section
              id={i}
              key={i}
              className={`container-medios-cards-desktop-item ${
                elemento.cardDesktop ? classes.cardHide : ""
              }`}
            >
              <section>
                <img
                  className="container-medios-cards-desktop-item-img"
                  loading="lazy"
                  src={elemento.image}
                  alt="article"
                />
                <p className="container-medios-cards-desktop-item-title">
                  <Link href={elemento.link} target="_blank" rel="noreferrer">
                    {" "}
                    {elemento.title}
                  </Link>
                </p>
                <p className="container-medios-cards-desktop-item-text">
                  {elemento.textbody}
                </p>

                <Link
                  href={elemento.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="container-medios-cards-desktop-item-button"
                >
                  Ver nota
                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.27567 18.0005C1.17418 18.001 1.07356 17.9821 0.979602 17.9448C0.885641 17.9075 0.80018 17.8525 0.728119 17.783C0.655836 17.7133 0.598463 17.6304 0.559311 17.539C0.520158 17.4476 0.5 17.3496 0.5 17.2506C0.5 17.1516 0.520158 17.0536 0.559311 16.9622C0.598463 16.8708 0.655836 16.7879 0.728119 16.7182L7.02881 10.5917C7.46207 10.1699 7.70543 9.59817 7.70543 9.00202C7.70543 8.40587 7.46207 7.83409 7.02881 7.41229L0.728119 1.28584C0.5829 1.14463 0.501316 0.95312 0.501316 0.753428C0.501316 0.553736 0.5829 0.362223 0.728119 0.221019C0.873339 0.0798156 1.0703 0.000488281 1.27567 0.000488281C1.48104 0.000488281 1.678 0.0798156 1.82322 0.221019L8.12391 6.34747C8.48301 6.69575 8.76791 7.1095 8.9623 7.56501C9.1567 8.02052 9.25676 8.50885 9.25676 9.00202C9.25676 9.49519 9.1567 9.98351 8.9623 10.439C8.76791 10.8945 8.48301 11.3083 8.12391 11.6566L1.82322 17.783C1.75116 17.8525 1.6657 17.9075 1.57174 17.9448C1.47778 17.9821 1.37717 18.001 1.27567 18.0005Z" />
                  </svg>
                </Link>
              </section>
            </section>
          )
        })}
        {pageAgradecimiento ? (
          ""
        ) : (
          <div className="container-medios-cards-desktop-button-view">
            <hr/>
            <button
              className="container-medios-cards-desktop-button-view-button"
              onClick={() => {
                setMostrar(!mostrar)
              }}
            >
              Ver {mostrar ? "menos" : "más"}
              <span>
                <IconButtonWrapper rotate={mostrar.toString()}>
                  <svg
                    width="19"
                    height="11"
                    viewBox="0 0 19 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.413226 0.41571C0.964981 -0.137159 1.86046 -0.138063 2.41333 0.413692L9.41608 7.40232L16.4188 0.413692C16.9717 -0.138063 17.8672 -0.137159 18.4189 0.41571C18.9707 0.96858 18.9698 1.86406 18.4169 2.41581L10.4151 10.4015C9.86304 10.9524 8.96912 10.9524 8.41704 10.4015L0.415244 2.41581C-0.137626 1.86406 -0.138529 0.96858 0.413226 0.41571Z"
                      fill="#262A31"
                    />
                  </svg>
                </IconButtonWrapper>
              </span>
            </button>
          </div>
        )}
      </div>

      <div className="container-medios-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map(elemento => {
            return (
              <section
                id={elemento}
                key={elemento.textbody}
                className="container-medios-cards-mobile-item"
              >
                <section>
                  <img
                    className={"container-medios-cards-mobile-item-img"}
                    loading="lazy"
                    src={elemento.image}
                    alt="article"
                  />
                  <p className="container-medios-cards-mobile-item-title">
                    <Link href={elemento.link} target="_blank" rel="noreferrer">
                      {" "}
                      {elemento.title}
                    </Link>
                  </p>
                  <p className="container-medios-cards-mobile-item-text">
                    {elemento.textbody}
                  </p>
                  <Link
                    href={elemento.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="container-medios-cards-mobile-item-button"
                  >
                    Ver nota
                    <svg
                      width="10"
                      height="18"
                      viewBox="0 0 10 18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1.27567 18.0005C1.17418 18.001 1.07356 17.9821 0.979602 17.9448C0.885641 17.9075 0.80018 17.8525 0.728119 17.783C0.655836 17.7133 0.598463 17.6304 0.559311 17.539C0.520158 17.4476 0.5 17.3496 0.5 17.2506C0.5 17.1516 0.520158 17.0536 0.559311 16.9622C0.598463 16.8708 0.655836 16.7879 0.728119 16.7182L7.02881 10.5917C7.46207 10.1699 7.70543 9.59817 7.70543 9.00202C7.70543 8.40587 7.46207 7.83409 7.02881 7.41229L0.728119 1.28584C0.5829 1.14463 0.501316 0.95312 0.501316 0.753428C0.501316 0.553736 0.5829 0.362223 0.728119 0.221019C0.873339 0.0798156 1.0703 0.000488281 1.27567 0.000488281C1.48104 0.000488281 1.678 0.0798156 1.82322 0.221019L8.12391 6.34747C8.48301 6.69575 8.76791 7.1095 8.9623 7.56501C9.1567 8.02052 9.25676 8.50885 9.25676 9.00202C9.25676 9.49519 9.1567 9.98351 8.9623 10.439C8.76791 10.8945 8.48301 11.3083 8.12391 11.6566L1.82322 17.783C1.75116 17.8525 1.6657 17.9075 1.57174 17.9448C1.47778 17.9821 1.37717 18.001 1.27567 18.0005Z" />
                    </svg>
                  </Link>
                </section>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}

export default Medios
