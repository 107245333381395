import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      width="133"
      height="13"
      viewBox="0 0 133 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.2366 10.7695C22.1342 9.72385 2.74663 11.5771 1.37731 11.3469C0.168558 10.9842 1.31216 3.70703 2.30584 2.59325C2.65819 2.19736 3.1015 2.10429 4.14676 2.25158C5.06355 2.37969 11.4292 2.02228 26.8248 1.27091C27.1173 1.4916 27.9072 0.724172 28.077 0.995462C28.2084 1.15388 30.4396 1.16908 33.7583 1.14551C37.4821 1.11772 47.6235 0.936365 52.6232 0.898412C60.0023 0.83306 57.229 0.795587 65.6732 0.815743C67.4396 0.81879 66.0438 1.23439 68.1256 1.11129C72.5654 0.855577 82.2241 1.51687 85.5866 1.26557C88.2835 1.06341 88.8694 1.06662 91.4391 1.4435C93.4949 1.74362 108.063 2.24504 109.725 1.86817C110.126 1.7525 110.425 1.82737 110.409 1.98672C110.581 2.00768 120.259 2.82975 120.296 2.96345C120.359 3.12598 120.647 3.22827 120.936 3.13578C121.459 2.97646 132.004 3.26378 132.13 3.74197C132.487 5.11993 130.477 10.6434 129.157 11.9316C127.774 13.2798 121.315 11.0877 115.677 11.5723C115.677 11.5723 114.095 11.4726 111.724 11.3125C88.562 9.80405 89.3377 10.2185 84.8976 10.4812C84.2896 9.96928 81.8747 11.0664 80.4376 10.1796C79.8408 9.81425 75.5308 9.6371 74.553 9.94469C74.3434 10.0126 72.8109 9.97742 70.582 9.90668C68.3616 9.83628 65.445 9.72336 62.4913 9.67152C61.0102 9.64542 59.5208 9.61203 58.108 9.60265C56.6868 9.58597 55.333 9.60684 54.1341 9.63407C51.7276 9.68818 49.9305 9.80212 49.3691 10.0434C45.0182 9.18288 29.4375 10.4553 25.2366 10.7695Z"
        fill="#FFBA00"
      />
    </svg>
  </span>
)

export default titleUnderline
